/* eslint-disable sonarjs/no-duplicate-string */
import React from "react";
import { Link } from "@components/shared/actions";
import { LINKS } from "@constants";
import { Section } from "@components/shared/pages";
import { Button } from "react-bootstrap";
import { SeguridadeFaq } from "../../../components/blocosCustomizados";

const linkLpSortudao = () => {
  window.open(LINKS.sortudao, "_blank");
};

const linkLpParcelaReduzida = () => {
  window.open(LINKS.parcelaReduzida, "_blank");
};

const linkDicionarioConsorcio = () => {
  window.open(LINKS.linkDicionarioConsorcio, "_blank");
};

const faqItems = [
  {
    label: "Quem pode fazer um consórcio?",
    content: (
      <>
        <p>Qualquer pessoa, com 18 anos ou mais.</p>
        <p>
          Mas lembre-se: você vai fazer um compromisso financeiro, então, antes
          de tudo, analise se suas escolhas estão compatíveis com a sua renda.
          Desde o valor do crédito até o valor que você terá que desembolsar
          mensalmente.
        </p>
      </>
    ),
  },
  {
    label: "O que é cobrado na parcela do seu consórcio?",
    content: (
      <>
        <p>
          No consórcio você não paga juros. Veja para o que o seu dinheiro será
          destinado:
        </p>

        <p>
          <strong>Fundo Comum:</strong> é o valor que será reunido para a compra
          do bem. É desse fundo que tiramos o crédito que será destinado aos
          contemplados e, havendo saldo no encerramento do grupo, será devolvido
          aos consorciados ativos e excluídos.
        </p>

        <p>
          <strong>Fundo de Reserva:</strong> esse fundo é essencial para manter
          a saúde financeira do grupo. O valor é destinado a uma reserva para
          casos de imprevistos como cobrir uma eventual falta de dinheiro no
          Fundo Comum e, havendo saldo no encerramento do grupo, o mesmo será
          devolvido aos consorciados ativos.
        </p>

        <p>
          <strong>Taxa de Administração:</strong> é o valor que a gente cobra
          para administrar seu consórcio. A Taxa de Administração é diferente
          dos juros cobrados em outras modalidades, por isso é bem menor. Ela é
          dividida durante o tempo contratado: um pedacinho em cada parcela.
        </p>

        <p>
          <strong>Seguro DFI - Danos Físicos ao Imóvel:</strong> exclusivo para
          o Consórcio Imobiliário, esse seguro é cobrado somente após a
          aquisição, construção, reforma e/ou ampliação do imóvel.
        </p>

        <p>
          <strong>Seguro Prestamista:</strong> Esse seguro é opcional, sendo
          cobrado a partir da 1º parcela. Em caso de morte ou invalidez total e
          permanente do titular, o seguro quita o saldo devedor do consórcio.
          Trata-se de uma escolha importante para quem quer cuidar da família.
          Se preferir, consulte as condições gerais:{" "}
          <Link
            blank
            href={LINKS.condicoesGeraisImobiliario}
            label="Consórcio Imobiliáro"
          />{" "}
          ou
          <Link
            blank
            href={LINKS.condicoesGeraisVeiculo}
            label="Consórcio de Veículos"
          />
          .
        </p>
      </>
    ),
  },
  {
    label: "O que são as assembleias?",
    content: (
      <>
        <p>
          Todos os meses é realizada uma reunião chamada Assembleia Geral
          Ordinária - AGO. É na AGO que acontece o sorteio e a avaliação dos
          lances que definirão os contemplados daquele respectivo mês.
        </p>

        <p>
          Temos também a Assembleia Geral Extraordinária - AGE, que pode ser
          convocada a qualquer momento, pela empresa ou pelo grupo, quando
          existir a necessidade de definir assuntos de interesse de todos.
        </p>
      </>
    ),
  },
  {
    label: "Qual a diferença entre sorteio e lance?",
    content: (
      <>
        <p>
          <strong>Sorteio:</strong> a apuração da cota sorteada é realizada
          mensalmente, com base no resultado da Loteria Federal anterior à data
          da assembleia. Mas lembre-se, caso não esteja com a parcela em dia,
          você não terá direito ao sorteio.
        </p>
        <p>
          <strong>Lance:</strong> é uma forma de você aumentar a chance de ser
          contemplado antecipando parcelas do seu consórcio e pode ser feito de
          duas formas:
        </p>
        <p>
          <strong>Lance fixo:</strong> você oferece o valor fixo de 20% do que
          ainda falta pagar do seu consórcio. Será contemplado aquele que, entre
          todos, possuir a cota mais próxima da cota sorteada pela Loteria
          Federal.
        </p>
        <p>
          <strong>Lance livre:</strong> neste tipo de lance você pode ofertar
          qualquer valor maior que sua parcela mensal. O lance é convertido em
          percentual e aquele que representar o maior valor será o contemplado.
          Em caso de empate, a cota contemplada será a de número mais próximo à
          cota sorteada.
        </p>
        <p>
          Ah! Você pode ofertar lance fixo e livre na mesma assembleia. Aí terá
          três chances de ser contemplado - sorteio, lance fixo e lance livre.
          Quando for contemplado a gente entra em contato :)
        </p>
        <p>
          <strong>
            Sabia que, em alguns grupos, você tem a opção de usar o valor do
            crédito para pagar o lance? <br />
            Utilize até 50% para o imobiliário e até 30% para veículos, conforme
            o seu contrato de adesão.
          </strong>
        </p>
        <p>
          É possível utilizar parte do valor do crédito para dar o lance sem
          precisar ter dinheiro. Mas, se você tem o dinheiro e quer complementar
          a oferta com o valor do crédito, suas chances de contemplação
          aumentam. Após a contemplação, você terá um novo valor de crédito
          disponível e pode optar por reduzir o valor das parcelas ou o prazo.
        </p>
      </>
    ),
  },
  {
    label: "Como é atualizado o valor do crédito?",
    content: (
      <>
        <p>
          O valor do crédito é corrigido de acordo com o Índice Nacional de
          Preços ao Consumidor - INPC. A cada 12 assembleias do grupo, o crédito
          é atualizado com ajuste da parcela e do saldo devedor. Assim, seu
          poder de compra é mantido.
        </p>
      </>
    ),
  },
  {
    label: "O que acontece quando eu sou contemplado?",
    content: (
      <>
        <p>
          Após sua contemplação, o valor do seu crédito será liberado depois da
          aprovação da análise de crédito e de garantia. Por isso, se você
          estiver com alguma restrição no nome, vai precisar regularizar sua
          situação. No Consórcio Imobiliário, o vendedor do imóvel também
          precisa estar sem restrições para que tudo seja aprovado.
        </p>
      </>
    ),
  },
  {
    label: "Como usar o FGTS no Consórcio Imobiliário?",
    content: (
      <>
        <p>
          Existem algumas opções para usar o Fundo de Garantia do Tempo de
          Serviço (FGTS) no seu Consórcio Imobiliário, de acordo com as regras
          do próprio fundo. São elas:
        </p>
        <ul>
          <li>Complementar o pagamento do valor do imóvel a ser adquirido;</li>
          <li>Utilizar como forma de pagamento de lance;</li>
          <li>Amortização ou liquidação do saldo devedor;</li>
          <li>Pagamento de parte das parcelas;</li>
          {/* <li>
            Pagar parte das parcelas do seu consórcio e também amortizar ou
            quitar o saldo devedor após a aquisição do imóvel.
          </li> */}
        </ul>
        {/* <p>
          <Link
            href={LINKS.linkRegrasFgts}
            label="Consulte as regras do FGTS"
          />
        </p> */}
      </>
    ),
  },
  {
    label: "Consórcio Sortudão",
    content: (
      <div className="flex-center">
        Contrate um Consórcio dentro do período da promoção e concorra a
        R$100.000,00.
        <br />
        <br />
        <Button className="blue-button" onClick={linkLpSortudao}>
          Saiba Mais
        </Button>
      </div>
    ),
  },
  {
    label: "Parcela Reduzida",
    content: (
      <>
        <div className="flex-center">
          <span>
            Até 31/01/25, ao adquirir um Consórcio Imobiliário você conta com a
            opção de Parcela Reduzida!
          </span>
          <br />
          <br />
          <Button className="blue-button" onClick={linkLpParcelaReduzida}>
            Saiba Mais
          </Button>
        </div>
      </>
    ),
  },
  {
    label: "Dicionário do Consórcio",
    content: (
      <div className="flex-center">
        Entenda o significado das palavras mais utilizadas no universo do
        consórcio para facilitar a realização de suas conquistas.
        <br />
        <br />
        <Button className="blue-button" onClick={linkDicionarioConsorcio}>
          Saiba Mais
        </Button>
      </div>
    ),
  },
];

export const SaibaMaisConsorcio = ({ sectionTitle }) => {
  return <SeguridadeFaq title={sectionTitle} faqItems={faqItems} right />;
};

SaibaMaisConsorcio.defaultProps = {
  ...Section.defaultProps,
};

SaibaMaisConsorcio.propTypes = {
  ...Section.propTypes,
};

export default SaibaMaisConsorcio;
