import amplieRenoveSuaFrota from "./amplie-renove-frotas.svg";
import entendaConsorcios from "./entenda-concorcios-ilustracao.svg";
import reduzaTaxaConsorcio from "./reduza-taxa-impostos-ilustracao.svg";
import semJurosConsorcio from "./sem-juros-ilustracao.svg";
import useSeuFgtsConsorcio from "./use-seu-fgts-ilustracao.svg";
import entregaBemContemplacao from "./entrega-bem-contemplacao-ilustracao.svg";
import valorCredito from "./valor-credito-ilustracao.svg";
import valorDinheiro from "./valor-dinheiro-ilustracao.svg";
import ilustracaoProximasAssembleias from "./proximas-assembleias-ilustracao.svg";
import comunicadoContemplacao from "./comunicado-contemplacao-ilustracao.svg";
import cotasContempladasNaoEncontrada from "./cotas-contempladas-nao-encontrada-ilustracao.svg";
import cotasContempladasEncontrada from "./cotas-contempladas-encontrada-ilustracao.svg";
import fraudeBackground from "./fraude-background.png";
import celularFraude from "./celular-fraude.png";
import cuidadoFraude from "./cuidado-fraude.png";
import familiaComCasaAmarela from "./familia-com-casa-amarela.png";
import mulherComLapis from "./mulher-com-lapis.png";
import pessoaFraude from "./pessoa-fraude.png";
import pessoasPositivas from "./pessoas-positivas.png";
import pessoasTransferindo from "./pessoas-transferindo.png";
import bannerAniversarioSeguridade from "./banner-aniversario-seguridade-desktop.png";
import bannerAniversarioSeguridadeMobile from "./banner-aniversario-seguridade-mobile.png";
import bannerAniversarioSeguridadeProrrogado from "./banner_site_aniversário_seguridade.png";
import bannerAniversarioSeguridadeProrrogadoMobile from "./banner_mobile_aniversário_seguridade.png";
import bannerLPSortudao from "./banner-lp-sortudao.png";
import bannerLPSortudaoMobile from "./banner-lp-sortudao-mobile.png";
import backgroundSortudao from "../background-sortudao.png";
import iconAquisicaoConsorcio from "../icon-aquisicao-consorcio.svg";
import iconImovel from "../icon-imovel.svg";
import iconHandshake from "./handshake.svg";
import iconHandleMoney from "./handle-money.svg";
import iconHouseKey from "./house-key.svg";
import iconHouseTree from "./house-tree.svg";
import iconNumeroSorte from "../icon-numero-sorte.svg";
import iconPremiado from "../icon-premiado.svg";
import iconPremio from "../icon-premio.svg";
import iconSejaSortudao from "../icon-seja-sortudao.svg";
import iconVeiculosLeve from "../icon-veiculos_leve.svg";
import iconVeiculosPesado from "../icon-veiculos_pesado.svg";
import bannerCDG from "./banner-cdg.png";
import bannerCDGMobile from "./banner-cdg-mobile.png";
import bannerContratacaoSegura from "./banner-contratacao-segura.png";
import bannerContratacaoSeguraMobile from "./banner-contratacao-segura-mobile.png";
import bannerLibras from "./banner-libras.png";
import bannerLibrasMobile from "./banner-libras-mobile.png";
import bannerLPSortudaoEncerrado from "./banner-sortudao-lp-encerrado.png";
import bannerLPSortudaoEncerradoMobile from "./banner-sortudao-lp-encerrado-mobile.png";
import bannerAutoCompraOfertaConsorcio from "./banner-auto-compra-oferta-consorcio.png";
import bannerAutoCompraOfertaConsorcioMobile from "./banner-auto-compra-oferta-consorcio-mobile.png";
import bannerSortudaoEncerrado from "./banner-sortudao-encerrado.png";
import bannerSortudaoEncerradoMobile from "./banner-sortudao-encerrado-mobile.png";
import bannerMesCliente from "./banner-mes-cliente.png";
import bannerMesClienteMobile from "./banner-mes-cliente-mobile.png";
import bannerSuperSexta from "./banner-super-sexta.png";
import bannerSuperSextaMobile from "./banner-super-sexta-mobile.png";
import bannerParcelaReduzida from "./banner-parcela-reduzida.png";
import bannerParcelaReduzidaMobile from "./banner-parcela-reduzida-mobile.png";
import bannerMesCliente2 from "./banner-mes-do-cliente-2.png";
import bannerMesCliente2Mobile from "./banner-mes-do-cliente-2-mobile.png";
import bannerSuperSexta2 from "./banner-super-sexta-2.png";
import bannerSuperSexta2Mobile from "./banner-super-sexta-2-mobile.png";
import bannerDescontao from "./banner-descontao.png";
import bannerDescontaoMobile from "./banner-descontao-mobile.png";
import parcelaReduzida from "./parcela-reduzida.png";
import parcelaReduzidaMobile from "./parcela-reduzida-mobile.png";
import parcela30 from "./parcela_30.png";
import nuvem from "./nuvem.png";

export default {
  amplieRenoveSuaFrota,
  entendaConsorcios,
  reduzaTaxaConsorcio,
  semJurosConsorcio,
  useSeuFgtsConsorcio,
  entregaBemContemplacao,
  valorCredito,
  valorDinheiro,
  ilustracaoProximasAssembleias,
  comunicadoContemplacao,
  cotasContempladasNaoEncontrada,
  cotasContempladasEncontrada,
  fraudeBackground,
  celularFraude,
  cuidadoFraude,
  familiaComCasaAmarela,
  mulherComLapis,
  parcelaReduzida,
  parcelaReduzidaMobile,
  parcela30,
  iconHandshake,
  iconHandleMoney,
  iconHouseKey,
  iconHouseTree,
  nuvem,
  pessoaFraude,
  pessoasPositivas,
  pessoasTransferindo,
  bannerAniversarioSeguridade,
  bannerAniversarioSeguridadeMobile,
  bannerAniversarioSeguridadeProrrogado,
  bannerAniversarioSeguridadeProrrogadoMobile,
  bannerLPSortudao,
  bannerLPSortudaoMobile,
  backgroundSortudao,
  iconAquisicaoConsorcio,
  iconImovel,
  iconNumeroSorte,
  iconPremiado,
  iconPremio,
  iconSejaSortudao,
  iconVeiculosLeve,
  iconVeiculosPesado,
  bannerCDG,
  bannerCDGMobile,
  bannerContratacaoSegura,
  bannerContratacaoSeguraMobile,
  bannerLibras,
  bannerLibrasMobile,
  bannerLPSortudaoEncerrado,
  bannerLPSortudaoEncerradoMobile,
  bannerAutoCompraOfertaConsorcio,
  bannerAutoCompraOfertaConsorcioMobile,
  bannerSortudaoEncerrado,
  bannerSortudaoEncerradoMobile,
  bannerMesCliente,
  bannerMesClienteMobile,
  bannerSuperSexta,
  bannerSuperSextaMobile,
  bannerParcelaReduzida,
  bannerParcelaReduzidaMobile,
  bannerMesCliente2,
  bannerMesCliente2Mobile,
  bannerSuperSexta2,
  bannerSuperSexta2Mobile,
  bannerDescontao,
  bannerDescontaoMobile
};
