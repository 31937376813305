/* eslint-disable max-len */
import React from "react";
import { Section } from "@components/shared/pages";
import { SeguridadeFaq } from "../../../components/blocosCustomizados";
import "../style.css";

const faqItems = [
  {
    label: "O que é Parcela Reduzida?",
    content: (
      <>
        <p>
          É um plano especial onde o cliente poderá pagar parcelas 30% menores,
          permitindo que esse saldo reduzido seja pago somente após a
          contemplação ou o 80º mês (o que ocorrer primeiro).
        </p>
      </>
    ),
  },
  {
    label: "Como vai funcionar o pagamento do saldo reduzido?",
    content: (
      <>
        <p>
          Esses 30% a menos não caracteriza desconto. Ou seja, será necessário
          pagá-lo após a contemplação ou o 80º mês (o que ocorrer primeiro).
          Nesse caso, o cliente terá 3 opções: <br /> - Amortizar esse saldo
          devedor através de lance (em caso de cliente contemplado por lance),
          mantendo o valor do crédito e a parcela reduzida;
          <br /> - Reduzir o valor da carta de crédito via downgrade, mantendo a
          parcela reduzida;
          <br /> - Diluir o saldo devedor nas parcelas a vencer, mantendo o
          valor da carta de crédito e aumentando a parcela.
        </p>
      </>
    ),
  },
  {
    label:
      "O que será necessário fazer para escolher a forma de pagamento do saldo reduzido?",
    content: (
      <>
        <p>
          O cliente deverá entrar em contato nos canais de atendimento para
          manifestar o seu desejo até 1 dia antes da 81ª Assembleia. Central de
          Relacionamento:
          <br />
          <span>0800 975 1000</span>
          <br />
          Atendimento das 8h às 21h, de segunda a sexta-feira, exceto em
          feriados nacionais.
        </p>
      </>
    ),
  },
  {
    label: "Funcionários poderão adquirir o Parcela Reduzida?",
    content: (
      <>
        <p>
          Sim, o funcionário poderá contratar um plano tradicional com desconto
          de funcionário
        </p>
      </>
    ),
  },
  {
    label:
      "Existe alguma regra de elegibilidade específica para contratar o plano Parcela Reduzida?",
    content: (
      <>
        <p>
          Não. O cliente passará pelas mesmas avaliações para contratar um plano
          tradicional, incluindo a capacidade financeira.
        </p>
      </>
    ),
  },
  {
    label:
      "Por qual período o cliente poderá contratar o plano Parcela Reduzida?",
    content: (
      <>
        <p>Esse benefício estará disponível do dia 01/10/2024 e 31/01/2025.</p>
      </>
    ),
  },
];

export const ParcelaReduzidaFAQ = ({ sectionTitle }) => {
  return (
    <SeguridadeFaq
      className="oi"
      title={sectionTitle}
      faqItems={faqItems}
      right
    />
  );
};

ParcelaReduzidaFAQ.defaultProps = {
  ...Section.defaultProps,
};

ParcelaReduzidaFAQ.propTypes = {
  ...Section.propTypes,
};

export default ParcelaReduzidaFAQ;
