/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { LINKS } from "@constants";
import { BannerContents } from "../../../components/shared/layout";
import { publishBanner } from "../../../utils";

export const BannerConsorcio = (props) => {
  const { images } = props;

  const publishBannerParcelaReduzidaDate = "2024-10-01 00:00:00";

  const trocaBanners310824 = "2024-08-31 23:59:59";

  const BannerParcelaReduzida = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerParcelaReduzida}
      backgroundImageMobile={images.bannerParcelaReduzidaMobile}
    />
  );

  const BannerDescontao = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerDescontao}
      backgroundImageMobile={images.bannerDescontaoMobile}
      href={LINKS.autoCompraConsorcio}
    />
  );

  const BannerAutoCompraOfertaConsorcio = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerAutoCompraOfertaConsorcio}
      backgroundImageMobile={images.bannerAutoCompraOfertaConsorcioMobile}
      href={LINKS.autoCompraConsorcio}
    />
  );

  const BannerSortudaoEncerrado = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerSortudaoEncerrado}
      backgroundImageMobile={images.bannerSortudaoEncerradoMobile}
      href={LINKS.sortudao}
    />
  );

  const BannerLibras = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerLibras}
      backgroundImageMobile={images.bannerLibrasMobile}
    />
  );

  const BannerContratacaoSegura = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerContratacaoSegura}
      backgroundImageMobile={images.bannerContratacaoSeguraMobile}
      href={LINKS.linkContratacaoSegura}
    />
  );

  const BannerCDG = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerCDG}
      backgroundImageMobile={images.bannerCDGMobile}
      href={LINKS.autoCompraConsorcio}
    />
  );

  return (
    <BannerContents {...props}>
      {BannerDescontao()}
      
      {publishBanner("show", publishBannerParcelaReduzidaDate) &&
        BannerParcelaReduzida()}

      {publishBanner("show", trocaBanners310824) &&
        BannerAutoCompraOfertaConsorcio()}

      {publishBanner("show", trocaBanners310824) && BannerSortudaoEncerrado()}

      {BannerLibras()}

      {BannerCDG()}

      {BannerContratacaoSegura()}
    </BannerContents>
  );
};

BannerConsorcio.defaultProps = { images: {} };

BannerConsorcio.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerConsorcio;
