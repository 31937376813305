import styled from 'styled-components';
import FraudeConsorcioBackground from '../../../asserts/images/consorcios/fraude-background.png';

export const Background = styled.div(({ theme }) => ({
  background: `url(${FraudeConsorcioBackground})`,
  backgroundSize: 'cover',
  height: '90vh',
  minHeight: '500px',
  display: 'flex',
  flexDirection: 'column',
  backgroundPositionX: '73%',
  [theme.breakpoint.lg()]: {
    backgroundPositionX: 'center',
    height: '80vh',
  },
}));

export const Container = styled.div(({ theme }) => ({
  width: '90%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  paddingLeft: '0',
  marginTop: '24px',
  maxWidth: '1140px',
  marginLeft: 'auto',
  marginRight: 'auto',
  height: '90vh',
  gap: '28px',
  justifyContent: 'center',
  [theme.breakpoint.lg()]: {
    height: '80vh',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    gap: '60px',
    marginBottom: '8%',
  },
}));

export const TitleText = styled.h1(({ theme }) => ({
  fontSize: '52px',
  color: '#fff',
  [theme.breakpoint.lg()]: {
    fontSize: '80px',
  },
}));

export const SubtitleText = styled.h1(({ theme }) => ({
  fontSize: '28px',
  color: '#fff',
  [theme.breakpoint.lg()]: {
    fontSize: '36px',
  },
}));

export const Box = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: '#454E4F',
  background: '#F7F7F7',
  padding: '21px 16px',
  margin: 'auto',
  marginBottom: '-100px',
  [theme.breakpoint.lg()]: {
    padding: '21px',
  },
  [theme.breakpoint.xl()]: {
    padding: '21px 0',
  },
}));

export const SectionCards = styled.div`
  background-color: #fb75b3;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h3 {
    color: #fff;
    text-align: center;
  }

  & > div.content-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 1024px;

    @media all and (min-width: 650px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 1.2rem;
  padding: 1rem 2rem;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  flex: 1;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  & > svg {
    width: 90px;
    height: 90px;
  }

  & > h5 {
    text-align: center;
    color: #606060;
    font-style: italic;
  }
  & > p {
    text-align: center;
  }
`;

export const SectionReducedInfo = styled.div`
  background-color: #67acfc;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 100%;
  }

  @media all and (min-width: 650px) {
    & > img {
      width: 70%;
    }
  }

  & > .content-info {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    border-bottom: 1px solid #fff;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    align-items: center;
    p {
      color: #fff;
      text-align: center;
      font-size: 24px;
      font-weight: 100;
      line-height: 40px;

      span {
        font-family: var(--font-brand-bold);
      }
      @media (min-width: 800px) {
        width: 60%;
      }
    }
  }

  & > h3 {
    color: #fff;
    text-align: center;
  }

  & > div.content-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 1024px;
    width: 100%;

    @media all and (min-width: 500px) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
`;

export const CardReducedInstallment = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  border-radius: 1.2rem;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  & > p {
    text-align: center;
    font-weight: 900;
    font-size: 16px;
  }

  & > h5 {
    text-align: center;
    color: #606060;
    font-style: italic;
    font-size: 24px;
    margin-bottom: 1rem;

    span {
      font-size: 24px;
    }
  }

  & > h5.installment {
    margin-top: 1rem;
    color: #fb75b3;
  }

  & > p.installment-details {
    margin: 0;
    span {
      text-decoration-line: line-through;
    }
  }

  @media all and(min-width: 550px) {
    min-width: 40%;
    max-width: 48%;
  }
  @media all and(min-width: 650px) {
    min-width: 22%;
    max-width: 24%;
  }
`;

export const SectionContentInfo = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1024px;
    align-items: center;
    border-bottom: 1px solid #b0b0b0;
    padding-bottom: 1rem;

    svg {
      width: 60%;
    }

    & > h3 {
      color: #fb75b3;
      text-align: center;
      margin-top: 1rem;
    }

    & > div.final-time {
      background-color: #fb75b3;
      display: flex;
      flex: 1;
      padding: 1rem;
      width: 100%;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      margin-bottom: 1rem;

      h3 {
        color: #fff;
        margin: 0;
        text-align: center;
      }
    }

    & > div.general-info {
      background-color: #67acfc;
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 1rem;
      width: 100%;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      margin-bottom: 1rem;
      -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      h3 {
        color: #fff;
        text-align: center;
        margin-bottom: 1rem;
      }
    }

    & > .general-info > .content-descriptions {
      display: flex;
      flex-direction: column;

      .description {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex: 1;

        img {
          width: 150px;
          height: 148px;
        }

        .column-text {
          display: flex;
          flex-direction: column;

          h5,
          p {
            color: #fff;
          }
        }
      }
    }
  }

  & > div.content > div.link-info {
    padding: 1rem 4rem;
    margin: 2rem 0;
    background-color: #ffaa59;
    border-radius: 12px;
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    &:hover {
      text-decoration: none;
    }

    h3 {
      margin: 0;
      color: #fff;
      text-align: center;
    }
  }

  @media (min-width: 650px) {
    & > div.content > svg {
      width: 40%;
    }

    & > div.content div.general-info div.content-descriptions {
      flex-direction: row;
      flex-wrap: wrap;

      .description {
        min-width: 48%;
        max-width: 50%;
      }
    }
  }
`;

export const SectionInfo = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 4rem;

  & > .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #b0b0b0;
    padding-bottom: 1rem;
    max-width: 1024px;

    p {
      font-size: 0.8rem;
      margin-top: 1rem;
    }
  }
`;
